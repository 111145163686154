import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/projects/website/src/templates/MDXPage/index.js";
import Def from '@components/Definition';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Def of="Free-Variable" parent="Function" mdxType="Def" />
    <p>{`A free-variable is a global variable or variable defined externally to the function.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// window is a free variable with respect to \`save\``}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`save`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`data`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`localStorage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'saved'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "/series/fp/going-functional#free-variable-partial-application-pattern"
      }}>{`free-variable partial-application pattern`}</a>{` to fix this problem and turn this function `}<a parentName="p" {...{
        "href": "/glossary/pure"
      }}>{`pure`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`checkWindowExists`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`typeof`}</span>{` window `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!==`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'undefined'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`saveWithContext`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`fn`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`data`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fn`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'saved'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// [...]`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`checkWindowExists`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` save `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`saveWithContext`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`localStorage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`localStorage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`If the above seems overkill, or needless, just remember that a runtime error can crash everything in JS land and a free benefit of the pattern is that you get the ability to inject mocks into your tests (e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`const save = saveWithContext(jest.fn())`}</code>{` for instance). So pick your battles.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      