// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-mdx-page-index-js": () => import("./../src/templates/MDXPage/index.js" /* webpackChunkName: "component---src-templates-mdx-page-index-js" */),
  "component---src-templates-verb-page-index-js": () => import("./../src/templates/VerbPage/index.js" /* webpackChunkName: "component---src-templates-verb-page-index-js" */),
  "component---src-templates-series-page-index-js": () => import("./../src/templates/SeriesPage/index.js" /* webpackChunkName: "component---src-templates-series-page-index-js" */),
  "component---src-pages-authenticate-js": () => import("./../src/pages/authenticate.js" /* webpackChunkName: "component---src-pages-authenticate-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-settings-js": () => import("./../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

