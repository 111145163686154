import ARITY from '@content/glossary/glossary-arity.mdx';
import BINARY from '@content/glossary/glossary-binary.mdx';
import COMBINATOR from '@content/glossary/glossary-combinator.mdx';
import COMPOSITION from '@content/glossary/glossary-composition.mdx';
import CONSTANT from '@content/glossary/glossary-constant.mdx';
import CURRY from '@content/glossary/glossary-curry.mdx';
import DETERMINISTIC from '@content/glossary/glossary-deterministic.mdx';
import FUNCTION from '@content/glossary/glossary-function.mdx';
import FREE from '@content/glossary/glossary-free-variable.mdx';
import HIGHER from '@content/glossary/glossary-higher-order-function.mdx';
import IDENTITY from '@content/glossary/glossary-identity.mdx';
import NULLARY from '@content/glossary/glossary-nullary.mdx';
import PURE from '@content/glossary/glossary-pure.mdx';
import REFERENTIAL from '@content/glossary/glossary-referential-transparency.mdx';
import SIDE from '@content/glossary/glossary-side-effect.mdx';
import TERNARY from '@content/glossary/glossary-ternary.mdx';
import UNARY from '@content/glossary/glossary-unary.mdx';
import React from 'react';
export default {
  ARITY,
  BINARY,
  COMBINATOR,
  COMPOSITION,
  CONSTANT,
  CURRY,
  DETERMINISTIC,
  FUNCTION,
  FREE,
  HIGHER,
  IDENTITY,
  NULLARY,
  PURE,
  REFERENTIAL,
  SIDE,
  TERNARY,
  UNARY,
  React
};