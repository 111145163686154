module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"mhh6emz"},"google":{"families":["Fira Sans","Fira Code"]}},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"glossary":"/vercel/workpath0/projects/website/src/templates/MDXPage/index.js","modules":"/vercel/workpath0/projects/website/src/templates/MDXPage/index.js","pages":"/vercel/workpath0/projects/website/src/templates/MDXPage/index.js","posts":"/vercel/workpath0/projects/website/src/templates/MDXPage/index.js","routes":"/vercel/workpath0/projects/website/src/templates/VerbPage/index.js","reviews":"/vercel/workpath0/projects/website/src/templates/MDXPage/index.js"},"remarkPlugins":[{"resolve":"remark-slug"},{"resolve":"remark-autolink-headings"}],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-embed-gist"},"gatsby-remark-check-links","gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Open Sorcerers","short_name":"Sorcerers","start_url":"/","theme_color":"#a2aeb3","background_color":"#3c053a","display":"standalone","icon":"static/open-sorcerers-icon.png"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
