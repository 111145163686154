import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/projects/website/src/templates/MDXPage/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://github.com/pikapkg/snowpack"
      }}>{`snowpack`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`Run npm packages directly in the browser. No Webpack, Parcel, or bundlers required.`}</p>
    </blockquote>
    <p>{`Seems pretty cool`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      