import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/projects/website/src/templates/MDXPage/index.js";
import Def from '@components/Definition';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Def of="Combinator" mdxType="Def" />
    <p>{`A combinator is a primitive `}<a parentName="p" {...{
        "href": "/glossary/higher-order-function"
      }}>{`higher-order function`}</a>{` without `}<a parentName="p" {...{
        "href": "/glossary/free-variable"
      }}>{`free-variables`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// identity combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`I`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// constant combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`K`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// apply combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`A`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// thrush combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`T`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// duplication combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`W`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// flip combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`C`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`y`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// compose combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`B`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`g`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`g`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// amalgamation combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`S`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`g`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`g`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// psi combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`P`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`g`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`y`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`g`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`g`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// fixed-point Y combinator`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Y`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`f`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`g`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`g`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`g`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`g`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`g`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`g`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// morphisms`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`K`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`K`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`I`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`C`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`W`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`I`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`I`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`C`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`W`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`K`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`S`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`K`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Often if you can identify combinators in your code, you can use them to simplify logical parts of the code because of a knowledge of certain `}<a parentName="p" {...{
        "href": "/glossary/morphism"
      }}>{`morphisms`}</a>{`.`}</p>
    <p>{`Read much more on `}<a parentName="p" {...{
        "href": "//en.wikipedia.org/wiki/Combinatory_logic"
      }}>{`wikipedia`}</a>{`. (The above definitions provided by `}<a parentName="p" {...{
        "href": "https://gist.github.com/Avaq/1f0636ec5c8d6aed2e45"
      }}>{`this gist`}</a>{`.)`}</p>
    <p>{`(A common reference is `}<a parentName="p" {...{
        "href": "//en.wikipedia.org/wiki/To_Mock_A_Mockingbird"
      }}>{`To Mock A Mockingbird`}</a>{` but your mileage may vary.)`}</p>
    <p>{`There's a lot `}<a parentName="p" {...{
        "href": "//en.wikipedia.org/wiki/SKI_combinator_calculus"
      }}>{`more`}</a>{` to read about but one of the reasons it's cool is that using just `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`S`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`K`}</code>{` above, you can define a `}<a parentName="p" {...{
        "href": "//en.wikipedia.org/wiki/Turing_completeness"
      }}>{`Turing complete`}</a>{` language.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      