import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/projects/website/src/templates/VerbPage/index.js";
import CoreTenets from '@posts/fp-core-tenets.mdx';
import List from '@components/Writing/List';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CoreTenets id="core-tenets-block" mdxType="CoreTenets" />
    <List title="FP Reading" customFilter={({
      frontmatter: fm
    }) => fm && fm.title && !fm.title.toLowerCase().includes('tenets')} mdxType="List" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      