import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/projects/website/src/templates/MDXPage/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "core-tenets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#core-tenets",
        "aria-label": "core tenets permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core Tenets`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`(Mostly) everything is functions all the way down. If it's more than a unary function i.e. `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`(a, b) => a + b`}</code>{`, they should be `}<a parentName="p" {...{
            "href": "/glossary/curry"
          }}>{`curried`}</a>{`.`}</p>
        <p parentName="li">{`Good functions are `}<a parentName="p" {...{
            "href": "/glossary/pure-function"
          }}>{`pure functions`}</a>{` `}{`—`}{` pure functions are `}<a parentName="p" {...{
            "href": "/glossary/deterministic"
          }}>{`deterministic`}</a>{`, meaning that with the same inputs they return the same outputs, they have no `}<a parentName="p" {...{
            "href": "/glossary/side-effect"
          }}>{`side-effects`}</a>{`, meaning they do not make change external to the body of the function and they are `}<a parentName="p" {...{
            "href": "/glossary/referential-transparency"
          }}>{`referentially transparent`}</a>{` so if you know the inputs you can compare them transparently with their outputs.`}</p>
        <p parentName="li">{`When choosing an order for parameters in a curried function, in general it makes most sense to order them by order-of-likely availability, with the last parameter as the one which is the primary subject of the function going into the pipe.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Prefer `}<a parentName="p" {...{
            "href": "/glossary/composition"
          }}>{`function composition`}</a>{` and transformation via `}<a parentName="p" {...{
            "href": "/glossary/higher-order-function"
          }}>{`higher-order functions`}</a>{` over inline function complexity. (See more examples in `}<a parentName="p" {...{
            "href": "/glossary"
          }}>{`the glossary`}</a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Avoid mutating or destructing data. Instead, make a copy of the data, and do whatever you want to that. This prevents hard-to-track errors and adheres to creating no side-effects.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Prefer `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`map`}</code>{` over other loops. Discrete iterable values are pure. For instance, a `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`for`}</code>{` loop which conditionally pushes into an array, especially an array (potentially accessible from a shared context which could also then mutate it).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Functions should cleave towards being as generic as possible. This aids both readability and logical reasoning.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`There's a form of FP which is called tacit or point-free which attempts to eschew defining parameters where possible, instead relying upon explicit function `}<a parentName="p" {...{
            "href": "/glossary/arity"
          }}>{`arity`}</a>{`. This is not mandatory to be writing functional code. However, as it is often less code to write and helps reinforce the idea that everything is a function (often a composed one). An example of a tacit function:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "js"
        }}><pre parentName="div" {...{
            "style": {
              "counterReset": "linenumber NaN"
            },
            "className": "language-js line-numbers"
          }}><code parentName="pre" {...{
              "className": "language-js"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`pipe`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token function"
              }}>{`slice`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`0`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`3`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` toUpper`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span></code><span parentName="pre" {...{
              "aria-hidden": "true",
              "className": "line-numbers-rows",
              "style": {
                "whiteSpace": "normal",
                "width": "auto",
                "left": "0"
              }
            }}><span parentName="span"></span></span></pre></div>
      </li>
    </ol>
    <p>{`(For a lot more, you could check out our `}<a parentName="p" {...{
        "href": "/references"
      }}>{`references`}</a>{` page or see the wiki page on `}<a parentName="p" {...{
        "href": "//en.wikipedia.org/wiki/Category_Theory"
      }}>{`category theory`}</a>{`)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      